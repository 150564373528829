import clsx from 'clsx'
import { IconButtonProps } from './icon-button'

export type { IconButtonProps }

export const IconButton: React.FC<React.PropsWithChildren<IconButtonProps>> = ({
  icon,
  type = 'button',
  children,
  ...props
}) => {
  return (
    <button
      type={type}
      {...props}
      className={clsx(
        // 'hover:text-white hover:bg-primary-600 focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-0 focus:ring-gray-800',
        'text-base border-0 text-base font-medium cursor-pointer',
        'flex items-center justify-around',
        'text-inherit disabled:text-gray-600 dark:disable:text-slate-200',
        'disabled:cursor-not-allowed',
        // added this default in order not to create regresions.
        props.className || ' gap-2 rounded-md py-2 px-2',
      )}
    >
      {icon}
      {children}
    </button>
  )
}
